import { createApp } from 'vue'
import { configuration } from '@dpgradio/creative'
import './scss/index.scss'
import App from './App.vue'

const soundSettings = window.soundSettings || {}

const initialize = async () => {
  try {
    await configuration.retrieveConfigForStation(soundSettings.stationId)
  } catch (error) {
    alert(`
      Could not load configuration.
      - Have you set up a configuration for this application in Dario (see [updateConfigSchema.js])? If you do not want a custom configuration, remove the application name from the [configuration.retrieveConfigForDetectedStation] call in [main.js].
      - Did you provide a station name? (e.g. through the URL parameter 'stationId') If you want to set the station name in code, replace the configuration retrieval call with [configuration.retrieveConfigForStation('<station-name>', 'sound-value')] in [main.js]. 

      ${error}
    `)
  }

  const app = createApp(App)

  app.mount('#sound-value')
}

initialize()
