<template>
  <div class="templateContainer" :class="[settings.theme]">
    <div id="spacer" />
    <div id="amount-container">
      <div id="pre-amount">{{ settings.actionName }} is nu</div>
      <div id="amount">{{ moneyAmount }}</div>
      <div id="after-amount">waard</div>
      <div id="cta">
        <a class="cta-button" :href="settings.ctaUrl" target="_parent">{{ settings.ctaText }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import { api } from '@dpgradio/creative'

export default {
  name: 'App',
  data() {
    return {
      settings: {
        actionName: '',
        ctaUrl: '',
        ctaText: '',
        baseUrl: '',
        ...window.soundSettings,
      },
      amount: 0,
    }
  },
  computed: {
    moneyAmount() {
      return (
        new Intl.NumberFormat('nl-NL', {
          style: 'currency',
          currency: 'EUR',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }).format(this.amount) + ',-'
      )
    },
  },
  async mounted() {
    this.$el.closest('#sound-value').style['background-image'] = `url(${this.settings.background})`

    const { amount } = await api.request().get('actions/het-geluid/amount')
    this.countTo(amount)
  },
  methods: {
    countTo(to) {
      const increment = to / 150
      let loopCount = 0
      this.amount = 0
      const interval = setInterval(() => {
        this.amount += increment
        loopCount++
        if (loopCount >= 150) {
          clearInterval(interval)
          this.amount = to
        }
      }, 20)
    },
  },
}
</script>
